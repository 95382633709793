.sol-mobile-header {
  //display: inline-block;
  text-align: center;
  background-color: @header-top-bg;
  height: 55px;
  position: absolute;
  top: 0;
  right: 0;
  & > div {
    position: relative;
    @media @sm {
      height: 55px;
    }
  }
  .sol-mobile-logo {
    display: none;
    @media @sm {
      display: block;
    }
  }
  @media @md-strict {
    .sol-mobile-logo {
      display: none;
    }
  }
  .sol-mobile-logo {
    float: left;
    margin-left: 15px;
    margin-top: 11px;
    height: 40px;
  }
  .sol-mobile-search {
    display: none;
    @media @md {
      display: block;
      background-color: @white;
      border: 0;
      position: absolute;
      top: 10px;
      bottom: 0;
      right: 80px;
      height: 50px;
      &:before {
        content: '\f002';
        font-size: 25px;
        font-family: FontAwesome;
      }
    }
    @media @sm {
      top: 0;
      right: 60px;
    }
  }
  .sol-hamburger-wrapper {
    .focus-outline;
    width: 55px;
    position: absolute;
    top: 10px;
    bottom: 0;
    right: 45px;
    background-color: transparent;
    border: 0;
    text-align: center;
    padding-top: 32px;
    @media @md {
      right: 15px;
    }
    @media @sm {
      top: 0;
      right: 0;
    }
    & .sol-hamburger {
      width: 35px;
      position: absolute;
      top: 8px;
      left: 50%;
      margin-left: -18px;
      & > span {
        display: block;
        width: 35px;
        height: 3px;
        border-radius: 2px;
        background-color: @black;
        transition: transform 500ms;
      }
      & .sol-hamburger-middle {
        margin-top: 6px;
        margin-bottom: 6px;
        transition: width 500ms;
      }
      &.sol-active {
        .sol-hamburger-top {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0px);
          width: 37px;
        }
        .sol-hamburger-middle {
          width: 0;
        }
        .sol-hamburger-bottom {
          transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0px);
          width: 37px;
        }
      }
      @media @sm {
        right: 10px;
      }
    }
    & .sol-hamburger-text {
      color: @black;
      font-size: 12px;
      font-weight: bold;
      font-family: @main-font;
    }
  }
}
