.sol-crisis-container {
  position: relative;
  background-color: @crisis-bg;
  color: @crisis-text;
  .transition(margin-top, 150ms, linear);

  & .sv-row {
    position: relative;
    padding: 25px 25px 20px 155px;
    display: inline-block;
    @media @md {
      padding: 20px 20px 20px 115px;
    }
    @media @sm {
      padding: 20px 20px 20px 60px;
    }
  }

  & i.fa-warning {
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -55px;
    font-size: 100px;
    line-height: 110px;
    @media @md {
      left: 30px;
      top: 20px;
      margin-top: 0;
      font-size: 45px;
      line-height: 50px;
    }
    @media @sm {
      left: 20px;
      font-size: 25px;
      line-height: 30px;
    }
  }

  .sol-crisis-message {
    .sol-clearfix;

    &-title {
      display: block;
      margin-bottom: 10px;
      color: @crisis-text;
      font-size: 16px;
      font-weight: 700;
      text-decoration: none;
    }

    &-desc {
      color: @crisis-text;
      line-height: 1.4em;
      @media @md {
        margin-bottom: 10px;
      }
      @media @sm {
        font-size: 12px;
        line-height: 20px;
      }
    }

    &-more {
      display: block;
      text-align: right;

      & a {
        padding: 4px 8px;
        margin-right: 10px;
        border: 1px solid @crisis-btn-border;
        border-radius: 2px;
        background-color: @crisis-btn-bg;
        color: @crisis-text;
        font-weight: 600;
        text-decoration: none;
        .transition(background-color);
        @media @sm {
          font-size: 12px;
        }

        &:hover {
          background-color: @crisis-btn-bg-hover;
        }
      }
    }
  }

  .sol-close-crisis {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    color: @crisis-text;
    cursor: pointer;
    @media @md {
      font-size: 0;
    }
    @media @sm {}

    & i.fa {
      margin-left: 10px;
      opacity: 0.55;
      @media @md {
        font-size: 20px;
      }
    }
  }
}

.sol-no-script {
  i.fa-warning {
    font-size: 50px;
    margin-top: -57px;
  }

  .sv-row {
    padding: 15px 20px 0 90px;
  }

  .sol-crisis-message-title {
    margin-bottom: 5px;
  }
}
