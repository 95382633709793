.sol-landing-large {
  position: absolute;
  top: 110px;
  left: 70px;
  right: 60px;
}

.sol-landing-large-icon {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 17px;
  display: block;
  width: 23px;
  height: 23px;
  background-color: none;
  border: 1px solid @color-1;
  color: @color-1;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  padding-left: 1px;
  top: -5px;
}

.sol-landing-large-header {
  display: block;
  font-size: 20px;
  line-height: 19px;
  margin-top: 2px;
  margin-bottom: 6px;
  color: @color-1;
}

.sol-landing-large-item {
  position: relative;
  padding-left: 33px;
  padding-right: 33px;
  margin-bottom: 33px;
  margin-top: 5px;
  &:hover{
    a{
      color: @black;
      text-decoration: underline;
    }
    h2.sol-landing-large-header {
      color: @black;
    }
  }
}

.sol-landing-large-item a {
  display: block;
  color: @color-1;
  text-decoration: none;
  line-height: 17px;

  h2 {
    line-height: 17px;
  }
  @media @ie10 {
    // CSS-hack för IE10 och uppåt
    line-height: 22px;

    h2 {
      line-height: 22px;
    }
  }
}

.sol-startpage-pages .sol-landing-large-header,
.sol-startpage-pages .sol-landing-small-header {
  color: white;
  font-size: 17px;
}

.sol-landing-large-text,
.sol-landing-small-text {
  .font-normal;
  line-height: 1.4em;
  font-size: 15px;
}

.sol-landing-large .sol-landing-large-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.sol-landing-large .sol-landing-large-col {
  background: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
  border-radius: 5px;
  width: auto!important;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 31%;
  flex: 1 0 31%;
  max-width: 31%;
  margin-left: 1%;
  margin-right: 1%;
  padding: 10px 10px 0;
}

@media (max-width: 991px) and (min-width: 768px) {
  .sol-landing-large .sol-landing-large-col {
    width: auto!important;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 48%;
    flex: 1 0 48%;
    max-width: 48%;
    background: transparent;
  }
}
@media (max-width:1140px) and (min-width:992px) {
  .sol-landing-large  {
    top: 60px;
  }
}   
@media (max-width:991px) {
  .sol-landing-large {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    background: #f9f9f7;
    padding-top: 30px;
  }
}

@media (max-width:767px) {
  .sol-landing-large {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    padding: 20px;
  }
  .sol-landing-large .sol-landing-large-col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    background: transparent;
    margin-bottom: 10px;
  }
}
