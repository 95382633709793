.sol-section {
  padding-top: 35px;
  padding-bottom: 20px;
  @media @md {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  @media @sm {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media @xs {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.sol-wide-content-wrapper {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
.sol-article-wrapper,
.sol-subpage-wrapper {
  & .sv-image-portlet {
    margin-bottom: 20px;
    .sv-portlet-image-caption {
      margin-top: 0;
      padding: 8px 5px;
      background-color: @img-text-bg;
      color: @img-text;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      font-family: @main-font;
      font-weight: 300;
    }
  }
}
