.sol-popular-pages {
  .sol-clearfix;
  background-color: @top-content-popular-bg;
  padding: 10px 15px 5px;
  @media @sm {
    background-color: @top-content-popular-bg-phone;
  }
  & > a,
  & > div {
    color: @top-content-popular-text;
    float: left;
    margin-bottom: 5px;
    @media @sm {
      font-size: 13px;
    }
  }
  & > div {
    display: inline-block;
    margin-right: 10px;
    font-weight: 600;
  }
  & > a {
    opacity: 0.7;
    margin-right: 15px;
    //text-decoration: none;
    .transition(opacity);
    &:hover {
      opacity: 1;
    }
    & i.fa {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      border: 1px solid @top-content-popular-text;
      border-radius: 50%;
      text-align: center;
      padding-left: 1px;
      padding-top: 1px;
    }
  }
}
