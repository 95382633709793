.sol-collapsible {
  &__header {
    .no-appearance;
    .focus-outline;
    position: relative;
    display: block;
    width: 100%;
    padding: 20px 50px 20px 20px;
    background-color: @box-decoration-header-bg;
    border-radius: 3px;
    color: @white;
    text-align: left;
    text-decoration: none;
    line-height: 1.1;
    font-weight: 400;
    font-size: 18px;
    font-family: @main-font;
    margin: 0;
    cursor: pointer;
    &__icon {
      display: block;
      width: 38px;
      height: 38px;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -18px;
      &::after,
      &::before {
        content: "";
        display: block;
        background-color: @white;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 3px;
        margin-left: -10px;
        margin-top: -3px;
        .transition(all);
      }
      &::after {
        .rotate(90deg);
      }
    }
    &--active {
      & .sol-collapsible__header__icon {
        &::after {
          .rotate(0deg);
        }
      }
    }
  }
  & .sol-collapsible__content,
  & .sv-decoration-content {
    display: none;
    background-color: @box-decoration-bg;
    //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    border-radius: 3px;
    padding: 20px;
    margin-left: 0px;
    margin-right: 0px;
    border: 1px solid #ebebeb;
    border-top: 0 none;
    & > *:last-child {
      margin-bottom: 0;
    }
    & p:last-of-type {
      margin: 0;
    }
    .sv-editing-mode & {
      display: block;
    }
  }
}
