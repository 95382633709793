footer {
  .sol-bottom {
    padding: 35px 0 50px;
    background-color: @footer-bg;
    & .sv-grid-main .sv-row > .sv-column-4 {
      padding-right: 30px;
    }
    .sol-footer-contact {
      &-item {
        display: block;
        position: relative;
        margin-bottom: 11px;
        text-decoration: none;
        color: @footer-text;

        & a,
        & p {
          display: block;
          text-decoration: none;
          color: @footer-text;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          margin: 0;
        }
        .transition(opacity);
        & i.fa:first-child {
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 17px;
        }
      }
      & a:hover {
        text-decoration: underline;
        color:@lgt-color-4;
      }
    }
    .sol-footer-links {
      &-item {
        display: block;
        position: relative;
        margin-bottom: 11px;
        padding-left: 15px;
        color: @footer-text;
        &--icon-links {
          & a::before {
            .icon(@fa-var-angle-right, 14px, block);

            width: 10px;
            height: 18px;
            opacity: 0.9;
            margin-right: 10px;
            line-height: 16px;
            text-align: center;
            font-size: 14px;
            position: absolute;
            left: 0;
            top: 1px;
            box-sizing: border-box;
          }
        }
        & a,
        & p {
          display: block;
          color: @footer-text;
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          margin: 0;
        }
        .transition(opacity);
        & i.fa:first-child {
          position: absolute;
          left: 0;
          top: 1px;
          font-size: 17px;
        }
      }
      & a:hover {
        text-decoration: underline;
        color:@lgt-color-4;
      }
    }

    .sol-social-media {
      .sol-clearfix;
      .sol-social-media-item > a {
        margin-right: 7px;
        border-radius: 50%;
        color: @footer-text;
        text-align: center;
        line-height: 34px;
        text-decoration: none;
        letter-spacing: 0;
        font-size: 16px;

        & span {
          text-decoration: none;
        }
    
        .transition(opacity);

        &>i.fa {
          width: 15px;
        }

        &:hover {
          text-decoration: underline;
          color:@lgt-color-4;
        }
      }
    }
  }
  h1,
  p {
    &.font-footer-header {
      margin-bottom: 20px;
      margin-top: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid fadeout(@footer-text, 80%);
      font-size: 14px;
      font-family: @main-font;
      color: @footer-text;
      line-height: 22px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }
  }
  p.font-footer-text {
    margin-bottom: 11px;
    font-size: 14px;
    font-family: @main-font;
    color: @footer-text;
    line-height: 22px;
    font-weight: 700;
    a:hover {
      color: fadeout(@footer-text, 30%);
    }
  }
}
