.sol-event-info {
  .sol-simple-box-decoration {
    margin-top: 0;
  }
  &-field {
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    & > span {
      margin-right: 10px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.sol-event-info-small {
  .sol-clearfix;
  margin-top: 10px;
  margin-bottom: 10px;
  &-item {
    margin-right: 20px;
    font-size: 14px;
    float: left;
    & i {
      margin-right: 6px;
      color: @event-item-icon-bottom;
    }
  }
}
