.sol-contact,
.sol-related {
  background-color: @related-box-bg;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 30px;
}
.sol-related {
  &-item {
    color: @related-box-link-text;
    margin-bottom: 10px;
    line-height: 22px;
    display: inline-block;
    &:hover {
      color: @related-box-text-hover;
      text-decoration: underline;
    }
    &-name {
      text-decoration: none;
      color: @related-box-link-text;
      .transition(color);
    }
    &-size {
      display: inline-block;
      color: @related-box-link-text;
      font-size: 12px;
      margin-left: 10px;
    }
    & i.fa {
      padding-left: 1px;
      padding-bottom: 16px;
      width: 17px;
      height: 17px;
      margin-right: 5px;
      border-radius: 50%;
      color: @related-box-icon;
      font-size: 16px;
      line-height: 15px;
      text-align: center;
      position: relative;
      top: 1px;
      &.sol-link-icon {
        border: 1px solid @related-box-icon;
      }
    }
    &:hover &-name {
      color: @related-box-link-hover;
    }
  }
  div &-item &-item-name {
    color: @related-box-text;
  }
}
.sol-contact {
  &-item {
    h3 {
      margin-top: 0;
      font-size: 18px;
      font-weight: 700;
    }
    h4 {
      margin-top: 20px;
      display: block;
      margin-bottom: 10px;
      font-weight: 700;
    }
    h5 {
      margin-top: 15px;
      display: block;
      margin-bottom: 8px;
      font-weight: 700;
    }
    p {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 1.2;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
