.sol-btn {
  .button;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;

  & > a {
    .button;
    display: inline;
    padding-bottom: 12px;
    margin-right: 10px;
  }
}
.sol-btn-small {
  .button-small();
}
.sv-text-portlet.sol-font-btn-small {
  .font-btn {
    padding-top: 4px;
    padding-bottom: 5px;
    margin-bottom: 0;

    & > a {
      .button-small;
      
      font-size: 13px;
      padding: 10px 12px 8px;

      @media @ie10 {
        // CSS-hack för IE10 och uppåt
        padding: 14px 12px 8px;
      }
    }
  }
}
