.sol-widget-decoration-wrapper a {
  //text-decoration: none;
}

.sol-widget-decoration {
  overflow: hidden;
  border-radius: 3px;
  background-color: @widget-bg;
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  //text-decoration: none;

  & &-img {
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    height: 170px;
    @media @md {
      height: 124px;
    }
    @media @sm {
      height: 250px;
    }
    &:before {
      content: '';
      background-color: @widget-hover-color;
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      .transition(opacity);
    }
  }

  & .sv-text-portlet {
    position: relative;
    max-height: 200px;
    min-height: 170px;
    padding: 25px 30px 20px;
    font-size: 13px;
    line-height: 20px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 55px;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff 85%);
    }
  }

  & .font-widget-heading {
    display: block;
    margin-bottom: 10px;
    color: @widget-heading-text;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
    //text-decoration: none;
    font-family: @main-font;
    .transition(color);
    text-align: center;
    //text-transform: uppercase;
    letter-spacing: 0.05em;
    a {
      color:@color-1;
      text-decoration: none;
    }
  }

  & .font-normal {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.4em;
  }
  &:hover {
    .sol-widget-decoration-img:before {
      opacity: 0.5;
    }
    .font-widget-heading {
      color: @widget-heading-text-hover;
    }
    .font-widget-heading a{
      color: @black;
      text-decoration: underline;
    }
  }
}
