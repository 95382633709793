.sol-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 20px 20px 20px 230px;
  border-top: 0 none;
  border-radius: 3px;
  background-color: @news-item-bg;
  color: @color-1;
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  //text-decoration: none;
  &:hover {
    color: @black;    
  }
  @media @md {
    padding-left: 160px;
  }
  @media @sm {
    padding-left: 20px;
    padding-top: 210px;
  }
  &.sol-no-img {
    padding-left: 60px;
    @media @sm {
      padding-top: 20px;
    }
  }
  & &-img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none;
    @media @md {
      width: 130px;
    }
    @media @sm {
      width: 100%;
      height: 200px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(background-color);
    }
  }
  & &-date {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    padding-top: 4px;
    background-color: @news-item-date-bg;
    color: @news-item-date-color;
    text-align: center;
    z-index: 2;
    @media @sm {
      left: -40px;
      top: -40px;
      width: 90px;
      height: 90px;
      padding-top: 40px;
    }
    &-day {
      display: block;
      font-size: 16px;
      line-height: 11px;
      font-weight: 700;
      @media @sm {
        font-size: 14px;
        line-height: 7px;
      }
    }
    &-month {
      font-size: 12px;
      line-height: 22px;
      display: block;
    }
  }
  &.sol-no-img &-date {
    background-color: @news-item-border;
  }
  & &-heading {
    display: block;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 800;
    .transition(color);
  }
  & a {
    color: @color-1;
    & :hover {
      color: @black;
    }
  }
  & &-event-info {
    .sol-clearfix;
    margin-top: 10px;
    &-item {
      margin-right: 13px;
      font-size: 11px;
      float: left;
      & i {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }
  &:hover &-img:before {
    background-color: @news-item-img-hover;
  }
  &:hover &-heading {
    color: @black;
    text-decoration: underline;
  }
}
.sol-startpage-news {
  li {
    margin-bottom: 0;
  }
  & .sol-article-item  {
  padding-left: 70px;
  margin-bottom: 3px;
  &-date {
  left: 10px;
  top: 50%;
  width: 50px;
  height: 50px;
  padding-top: 4px;
  border-radius: 50%;
  margin-top: -25px;
}
  &-heading {
    font-weight: 300;
  }

}
+.sol-font-btn-small {
  margin-top: 15px;
}
}
