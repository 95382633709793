/* subpage/page-listing.less */
.sol-page-listing {
  border: 1px solid @page-listing-border;
  border-radius: 3px;
  background-color: @page-listing-bg;
  padding: 20px 0 15px 30px;
  margin-bottom: -28px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  ul {
    .no-list-style;
  }
  &-item {
    margin-bottom: 10px;
    &__link {
      color: @page-listing-item-icon-bg;
      &:hover {
        text-decoration: underline;
        color: @page-listing-child-hover;
      }
    }
    &__name {
      margin-bottom: 12px;
      margin-top: 0;
      color: @page-listing-item-icon-bg;
      font-size: 18px;
      &:hover {
        color: @page-listing-child-hover;
      }
    }
    &__icon {
      font-size: 17px;
      float: left;
      margin-right: 7px;
      height: 20px;
      width: 20px;
      color: @page-listing-item-icon-bg;
      background-color: none;
      border: 1px solid @page-listing-item-icon-bg;
      border-radius: 50%;
      text-align: center;
      position: relative;
      padding-left: 1px;
      padding-top: 0;
      margin-left: -12px;
      top: -1px;

      @media @ie10 {
        // CSS-hack för IE10 och uppåt
        top: -3px;
      }
    }
  }
  &-child {
    &__name {
      font-size: 15px;
      
    }
    &__link {
      display: block;
      margin-bottom: 10px;
      font-size: 13px;
      color: @page-listing-child-color;
      .transition(color);
      &:hover {
        text-decoration: underline;
        color: @page-listing-child-hover;
      }
      & i.fa-external-link {
        font-size: 11px;
        position: relative;
        top: -1px;
        margin-left: 5px;
      }
    }
    &__icon {
      font-size: 18px;
      float: left;
      margin-right: 8px;
      color: @page-listing-child-icon-color;
      position: relative;
      top: 1px;
      padding-left: 2px;
      @media @ie11 {
        top: -2px;
      }
    }
  }
}
