.sol-mobile-menu-wrapper {
  position: relative;
  display: block;
}

.sol-mobile-menu-container {
  display: none;
  background-color: #f9f9f7;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  z-index: 49;
  height: 0;
  min-height: 0;
  .transition(~'height, min-height', 250ms);
  min-width: 321px;
  width:auto;
  overflow: auto;
  //box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.43);
  &.sol-active  {
    height: calc(100vh - 60px)!important;
  }
  @media @md {
    &.sol-active {
      display: block;
      width: 100%;
      left: 0;
      
    }
  }

  & > ul {
    border-top: 1px solid @mobile-menu-border;
  }

  & .sol-menu-list {
    width: 100%;
    &-expanded-init {
      display: block;
    }
  }

  & .sol-menu-item {
    position: relative;
    &-link {
      .focus-outline;
      display: block;
      position: relative;
      padding: 20px 50px 18px 20px;
      border-bottom: 1px solid @mobile-menu-border;
      text-decoration: none;
      margin-left: 1px;
      margin-right: 1px;
      color: @mobile-menu-text;
      & :hover {
        color:@black;
      }

      & .sol-menu-item-display-name {
        font-size: 16px;
        font-weight: 300;
      }
    }
    & .sol-menu-button {
      .no-appearance;
      .focus-outline;
      position: absolute;
      top: 0;
      height: 60px;
      right: 0;
      width: 50px;
      border-left: 1px solid @mobile-menu-border;
      border-radius: 0;
      & i {
        color:@color-1;
        position: absolute;
        right: 16px;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        text-align: center;
        padding-top: 3px;
        padding-left: 3px;
        .transition(all);
      }
    }

    &[data-menu-level='1'] .sol-menu-item-link {
      padding-left: 30px;
    }

    &[data-menu-level='2'] .sol-menu-item-link {
      padding-left: 40px;
    }

    &[data-menu-level='3'] .sol-menu-item-link {
      padding-left: 50px;
    }

    &[data-menu-level='4'] .sol-menu-item-link {
      padding-left: 60px;
    }

    &[data-expanded='true'] > .sol-menu-button {
      & i.fa {
        .rotate(90deg);
      }
    }

    &[data-expanded='true'][data-has-children='false'],
    &[data-is-current='true'] {
      & > .sol-menu-item-link {
        color: @mobile-menu-text-active;
        background-color: @mobile-menu-bg-active;
      }
      & > .sol-menu-button {
        & i.fa {
          color: white;
        }
      }
    }
    &[data-expanded='true'] ul {
      background: #f7f7f7;
    }
    &[data-node-type='sv:folder'] {
      & > .sol-menu-button {
        width: 100%;
        border-left: none;
      }
    }
  }
}
