header {
  position: relative;
  z-index: 50;
  background-color: @header-top-bg;

  .sv-searchform-portlet {
    position: absolute;
    top: 0;
    width: 100%;
    @media @md {
      position: relative;
      top: auto;
      width: auto; 
    }
  }
  .sol-top-search {
    position: absolute;
    overflow: hidden;
    max-width: 280px;
    z-index: 51;
    display: block;
    right: 160px;
    width: 320px;
    left: auto;
    top: 12px;
    @media @lg {
      right: 155px;
    }
    @media @md {
      .transition(~ 'background-color, height');
      height: 0;
      display: none;
      margin-top: 0;
      max-width: 100%;
    }

    & form {
      position: relative;
      display: block;
      width: 100%;
      @media @md { 
        margin-top: 25px;
        margin-bottom: 25px;
      }
      @media @sm {
        width: 70%;
        margin: 20px auto;
      }
      @media @xs {
        width: 90%;
      }
    }
  }
  & .sol-top-search--open .sol-top-search {
    height: 115px;
    background-color: @search-top-bg-active;
    @media @md {
      height: 100px;
      max-width: 100%;
      top: 0;
      left: 0;
      margin-left: 0;
    }
    @media @sm {
      top: 59px;
      width: auto;
      left: 0;
      right: 0;
      height: 85px;
    }
  }

  .sol-top {
    padding: 20px 0;
    background-color: @header-top-bg;
    border-bottom: 5px solid #ebebeb;
    @media @md {
      padding: 20px 0 0;
    }
    @media @sm {
      text-align: center;
      background-color: @header-top-mobile-bg;
      padding-top: 13px;
      padding-bottom: 8px;
    }
    @media @sm {
      padding-left: 7px;
      padding-right: 7px;
    }

    .sol-top-logo {
      & > img {
        height: 70px;
        @media @md {
         height: 50px;
        }
      }
      @media @md {
        display: block;
        text-align: left;
      }
    }
    .sv-grid-main {position: relative;}
  }

  .sol-bottom {
    background-color: @header-menu-bg;
    text-align: center;
    @media @md {
      display: none;
    }
  }
}

.sol-to-content {
  position: absolute;
  right: -5000px;
  top: -3px;
  width: 135px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  background-color: @color-1;
  font-size: 14px;
  color: @white;
  &:focus {
    right: 100%;
    @media @sm {
      position: static;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
