.sol-a-z-icon {
  font-size: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.sol-letters {
  .sol-clearfix;
  max-width: 450px;

  a {
    padding: 6px 0;
    text-align: center;
    height: 25px;
    width: 25px;
    color: @button-small-text;
    background-color: @button-small-bg;
    box-shadow: none;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 10px;
    float: left;

    &.sol-active,
    &:hover {
      background-color: @button-small-bg-hover;
      color: @button-small-text-hover;
    }
  }
}
