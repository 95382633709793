.sol-subpage-wrapper {
  .sol-clearfix;
  > .sol-top {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    @media @md {
      //margin-left: -10px;
      //margin-right: -10px;
      padding-left: 10px;
      padding-right: 10px;
      max-width: 100%;
      display: inline-block;
    }
  }
  > .sol-bottom {
    max-width: 1191px;
    padding-bottom: 97px;
    position: relative;
    @media @md {
      padding-bottom: 30px;
    }
  }
  > .sol-left {
    width: 65%;
    float: left;
    @media @md {
      float: none;
      width: 100%;
    }
  }
  > .sol-right {
    width: 30%;
    float: right;
    @media @md {
      float: none;
      width: 100%;
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
}
