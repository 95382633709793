
.sol-popular-services { 
  & a {
    display: block;
    position: relative;
    padding: 20px 10px 20px 35px;
    border-bottom: 1px solid @services-border;
    color: @color-1;
    font-size: 15px;
    line-height: 20px;
    .transition(color);
    &:hover {
      color: @black;
      text-decoration: underline;
    }
    & i.fa {
      font-size: 14px;
      width: 18px;
      height: 18px;
      margin-right: 8px;
      color: @services-icon;
      border: 1px solid @services-icon;
      border-radius: 50%;
      text-align: center;
      padding-left: 1px;
      padding-top: 1px;
      position: absolute;
      left: 9px;
      top: 30px;
      margin-top: -11px;
    }
  }

  @media @ie10 {
    // CSS-hack för IE10 och uppåt
    a{
      i{
        line-height: 13px;
      }
      span {
        line-height: 22px;
      }
    }
  }
}
