.sol-tool-nav {
  //float: right;
  //margin-top: 4px;
  //position: relative;
  display: inline-block;
  background:@white;
  padding: 10px 10px 5px 0;
  position: absolute;
  right: 405px;
  top: 12px;
  //border-bottom: 1px solid #efefef;

  @media @md {
    vertical-align: middle;
    float: none;
    margin-top: 0;
    padding: 10px 10px 0;
    border-top: 1px solid #ebebeb;
    margin-top: 20px;
    position: relative;
    right: auto;
    top: 0;
    width: 100%
  }

  &-item {
    display: inline-block;
    //float: left;
    @media @md {
      float: none;
    }
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;
      margin-left: 5px;
      margin-right: 5px;
      font-weight: 400;
      color: @header-top-text;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
      font-family: @main-font;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      &:hover {
        color:@black;
      }
      @media @md {
        color: @header-top-mobile-text;
        margin: 0 9px 9px;
      }
      @media @sm {
        margin: 0 2px 2px;
        font-size: 10px;
      }
      & i.fa {
        margin-right: 5px;
        font-size: 15px;
      }
    }

    & .sol-close {
      .focus-outline;
      .no-appearance;
      position: absolute;
      top: 0;
      right: 0;
      color: @header-top-text;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      padding: 0;

      & i.fa {
        font-size: 11px;
      }
    }
  }

  &-box {
    position: absolute;
    width: 189px;
    margin-top: 10px;
    margin-left: -60px;
    padding: 17px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .2);
    text-align: center;
    z-index: 1;
    cursor: default;
  @media @md-strict {
      margin-left: 0;
      left: 45px;
    }
    @media @sm {
      left: 50%;
      margin-left: -109px;
      width: auto;
      padding: 12px;
    }

    & a.sol-btn-round {
      .transition(border);
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      color: @header-top-box-share-icon;
      border: 2px solid @header-top-box-share-border;
      border-radius: 50%;
      font-size: 15px;
      padding-top: 2px;
      text-align: center;
      @media @sm {
        width: 40px;
        height: 40px;
        font-size: 21px;
        padding-top: 0;
        line-height: 37px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border-color: @header-top-box-share-hover;
      }
    }

    &-translate {
      padding: 12px 17px 9px;
    }
  }
}
// READSPEAKER

.sv-readspeaker_expandingplayer-portlet {
  position: relative;

  & > a {
    display: none;
  }

  & div[id^=readspeaker_player].rsexpanded {
    position: absolute;
    right: 0;
    height: auto;
    width: auto !important;
    padding: 17px 17px 22px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0;
    text-align: center;
  }
}
